// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a3WJa5LX\\+yqKEVD658Y91w\\=\\={align-items:center;border-radius:4px;cursor:pointer;display:flex;gap:8px;padding:8px;transition:.3s}.a3WJa5LX\\+yqKEVD658Y91w\\=\\=:hover{background-color:rgba(0,0,0,.04)}.a3WJa5LX\\+yqKEVD658Y91w\\=\\=:before{border-bottom:2px solid #212121;border-left:2px solid #212121;content:\"\";display:block;margin-right:8px;min-height:24px;min-width:24px;transform:translate(50%,-50%)}.ip1kVP9xYLsXoC2APNCzwA\\=\\={display:flex;flex-grow:1;min-width:0}", "",{"version":3,"sources":["webpack://./resources/scripts/favorites-modal/components/create-subfolder/style.module.css"],"names":[],"mappings":"AAAA,6BAIE,kBAAmB,CAEnB,iBAAkB,CADlB,cAAe,CAJf,YAAa,CACb,OAAQ,CACR,WAAY,CAIZ,cACF,CAEA,mCACE,gCACF,CAEA,oCAME,+BAAgC,CADhC,6BAA8B,CAJ9B,UAAW,CACX,aAAc,CAKd,gBAAiB,CAHjB,eAAgB,CADhB,cAAe,CAKf,6BACF,CAEA,4BACE,YAAa,CACb,WAAY,CACZ,WACF","sourcesContent":[".child {\n  display: flex;\n  gap: 8px;\n  padding: 8px;\n  align-items: center;\n  cursor: pointer;\n  border-radius: 4px;\n  transition: 0.3s;\n}\n\n.child:hover {\n  background-color: rgba(0, 0, 0, 4%);\n}\n\n.child::before {\n  content: \"\";\n  display: block;\n  min-width: 24px;\n  min-height: 24px;\n  border-left: 2px solid #212121;\n  border-bottom: 2px solid #212121;\n  margin-right: 8px;\n  transform: translate(50%, -50%);\n}\n\n.input {\n  display: flex;\n  flex-grow: 1;\n  min-width: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"child": "a3WJa5LX+yqKEVD658Y91w==",
	"input": "ip1kVP9xYLsXoC2APNCzwA=="
};
export default ___CSS_LOADER_EXPORT___;
