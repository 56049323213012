// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UxCHXYa4KJfeIF8zVORI2w\\=\\=,.bMxCtdoBmeqYQmC1z1cR0Q\\=\\={align-items:center;border-radius:4px;cursor:pointer;display:flex;gap:8px;padding:8px;transition:.3s}.UxCHXYa4KJfeIF8zVORI2w\\=\\=:hover,.bMxCtdoBmeqYQmC1z1cR0Q\\=\\=:hover{background-color:rgba(0,0,0,.04)}.UxCHXYa4KJfeIF8zVORI2w\\=\\=:before{border-bottom:2px solid #212121;border-left:2px solid #212121;content:\"\";display:block;margin-right:8px;min-height:24px;min-width:24px;transform:translate(50%,-50%)}", "",{"version":3,"sources":["webpack://./resources/scripts/favorites-modal/components/main-folder/style.module.css"],"names":[],"mappings":"AAAA,wDAKE,kBAAmB,CAEnB,iBAAkB,CADlB,cAAe,CAJf,YAAa,CACb,OAAQ,CACR,WAAY,CAIZ,cACF,CAEA,oEAEE,gCACF,CAEA,mCAME,+BAAgC,CADhC,6BAA8B,CAJ9B,UAAW,CACX,aAAc,CAKd,gBAAiB,CAHjB,eAAgB,CADhB,cAAe,CAKf,6BACF","sourcesContent":[".folder,\n.child {\n  display: flex;\n  gap: 8px;\n  padding: 8px;\n  align-items: center;\n  cursor: pointer;\n  border-radius: 4px;\n  transition: 0.3s;\n}\n\n.folder:hover,\n.child:hover {\n  background-color: rgba(0, 0, 0, 4%);\n}\n\n.child::before {\n  content: \"\";\n  display: block;\n  min-width: 24px;\n  min-height: 24px;\n  border-left: 2px solid #212121;\n  border-bottom: 2px solid #212121;\n  margin-right: 8px;\n  transform: translate(50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"child": "UxCHXYa4KJfeIF8zVORI2w==",
	"folder": "bMxCtdoBmeqYQmC1z1cR0Q=="
};
export default ___CSS_LOADER_EXPORT___;
