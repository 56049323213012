// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FZ4GaQRK2ecXjIdVznj7Rg\\=\\={display:flex;flex-direction:column}.pEW8sSNoLKhXRS2WjOywPw\\=\\={align-items:center;background-color:transparent;border:none;color:#747474;cursor:pointer;display:flex;gap:16px;margin:16px 0;padding:8px 16px;transform:.3s}.pEW8sSNoLKhXRS2WjOywPw\\=\\=:hover{background-color:rgba(0,0,0,.04)}", "",{"version":3,"sources":["webpack://./resources/scripts/favorites-modal/components/app/style.module.css"],"names":[],"mappings":"AAAA,4BACE,YAAa,CACb,qBACF,CAEA,4BAEE,kBAAmB,CAInB,4BAA6B,CAD7B,WAAY,CAIZ,aAAc,CADd,cAAe,CAPf,YAAa,CAEb,QAAS,CACT,aAAc,CAGd,gBAAiB,CAGjB,aACF,CAEA,kCACE,gCACF","sourcesContent":[".folders {\n  display: flex;\n  flex-direction: column;\n}\n\n.newFolder {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  margin: 16px 0;\n  border: none;\n  background-color: transparent;\n  padding: 8px 16px;\n  cursor: pointer;\n  color: #747474;\n  transform: .3s;\n}\n\n.newFolder:hover {\n  background-color: rgba(0, 0, 0, 4%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"folders": "FZ4GaQRK2ecXjIdVznj7Rg==",
	"newFolder": "pEW8sSNoLKhXRS2WjOywPw=="
};
export default ___CSS_LOADER_EXPORT___;
