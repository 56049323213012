// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Wsj1rAbdoC3oLW8eHIwCOQ\\=\\={align-items:center;display:flex;gap:8px;padding:8px;text-decoration:none;width:100%}.k5zFMmXvLCEi9g3WLLBr1Q\\=\\={display:flex;flex-grow:1;min-width:0}", "",{"version":3,"sources":["webpack://./resources/scripts/favorites-modal/components/create-folder/style.module.css"],"names":[],"mappings":"AAAA,4BAGE,kBAAmB,CADnB,YAAa,CAEb,OAAQ,CAER,WAAY,CADZ,oBAAqB,CAJrB,UAMF,CAEA,4BACE,YAAa,CACb,WAAY,CACZ,WACF","sourcesContent":[".folder {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  text-decoration: none;\n  padding: 8px;\n}\n\n.input {\n  display: flex;\n  flex-grow: 1;\n  min-width: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"folder": "Wsj1rAbdoC3oLW8eHIwCOQ==",
	"input": "k5zFMmXvLCEi9g3WLLBr1Q=="
};
export default ___CSS_LOADER_EXPORT___;
